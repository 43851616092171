.card__edit{
  display: block;
  text-align: right;
  width: 50%;
  margin: 0 0 0 auto;
  margin-bottom: -20px;
  position: relative;
}

.card__delet{
  display: block;
  margin-bottom: -35px;
  text-align: left;
  position: relative;
}