.adm-header{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--thirdly-color);
  box-shadow: 0px 5px 5px rgba(0,0,0,.2);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: 1.25rem;
}

.adm-header__logo{
  margin-left: 5%;
}

.adm-header__logo__img{
  width: 100px;
}

.adm-header__list{
  display: flex;
}

.adm-header__list__link{
  font-size: 1rem;
  color: var(--secunday-color);
  margin-left: 2rem;
  transition: all .5s;
}

.adm-header__list__link:hover{
  color: var(--primary-color);
}

.adm-header__menu{
  display: flex;
  align-items: center;
  margin-right: 5%;
}

.adm-header__search{
  padding: .5rem;
  border-radius: 1rem;
  width: 150px;
  padding-left: 45px;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-image: url(../../assets//img/icon/search.png);
  background-size: 15px;
}

.adm-header__search:focus{
  box-shadow: 0 4px 4px rgba(0, 0, 0, .5);
}

@media screen and (max-width: 940px) {

  .adm-header__btn{
      display: block;
      background-image: url("../../assets/img/icon/hamburger.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 45px;
      height: 31px;
      margin-left: 20px;
      cursor: pointer;
  }

  .adm-header__nav{
      background-color: var(--thirdly-color);
      transition: all .8s;
      position: absolute;
      width: 40%;
      min-width: 150px;
      left: calc(-100% - 10rem);
      top: 6.2rem;
      z-index: 2;
      border-radius: 0 0 10px 0;
  }

  .adm-header__nav--open{
      left: 0%;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, .3);
  }

  .adm-header__list{
      flex-direction: column;
      text-align: center;
  }

  .adm-header__list__item{
      border-right: 0;
      padding: 0;
      margin-right: 0;
  }

  .adm-header__list__link{
      margin: 0;
      padding: 1rem 0 1rem 0;
      border-bottom: .13rem solid rgba(0, 0, 0, 0.2);
      width: 100%;
  }

  .adm-header__list__link--last{
    border: 0;
  }
}