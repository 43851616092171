.services{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  row-gap: 100px;
  width: 80%;
  margin: 0 auto;
  margin-top: 5rem;
  align-items: top;
}

.services__img{
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, .3);
  margin: 1rem;
  background-image: url(https://ak.picdn.net/shutterstock/videos/5495999/thumb/1.jpg);
}

.services__subtitle{
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  font-weight: bold;
}

.services__paragraph{
  line-height: 1.5rem;
}

@media screen and (max-width: 1180px) {

  .services{
    width: 80%;
  }

}

@media screen and (max-width: 945px) {

  .services{
    width: 90%;
  }

}

@media screen and (max-width: 840px) {

  .services{
    grid-template-columns: 1fr;
    width: 83%;
    max-width: 500px;
    justify-items: center;
    column-gap: 0;
    row-gap: 0;
    margin-top: 0;
  }

  .services__img{
    width: 100%;
    height: auto;
  }

  .services__subtitle{
    margin-top: 2.5rem;
    font-size: 1.5rem;
  }

  .services__paragraph{
    text-align: justify;
  }

  .item__text--1{
    order: 1;
  }
  
  .item__text--2{
    order: 3;
  }
  
  .item__text--3{
    order: 5;
  }

  .item__text--4{
    order: 7;
  }

  .item__text--5{
    order: 9;
  }

  .item__img--1{
    order: 2;
  }
  
  .item__img--2{
    order: 4;
  }
  
  .item__img--3{
    order: 6;
  }

  .item__img--4{
    order: 8;
  }

  .item__img--5{
    order: 10;
  }

}