.login__group__field {
  border: none;
  border-radius: 7px;
  background: rgba(0, 0, 0, .25);
  color: #fdfdfd;
  margin-top: 1rem;
  padding: .75rem;
  font-size: 1.2rem;
  width: 100%;
  transition: all .15s linear;
}

.login__group__field:focus {
  box-shadow: 0 4px 4px rgba(0, 0, 0, .5);
  outline: none;
}

.login__group__field::placeholder {
  color: #fdfdfd;
}

.login__group__error {
  color: #ff9494;
  line-height: 1.7rem;
}

.login__btn {
  background: #fdfdfd;
  color: #02040F;
  font-weight: bold;
  padding: .5rem 2rem;
  border: none;
  border-radius: 5px;
  margin-top: 1.5rem;
}

.login__btn:hover {
  cursor: pointer;
}