.offers{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0 auto;
  width: 80%;
  max-width: 1100px;
}

.offers__link{
  display: block;
  margin: 1rem auto 0 auto;
  text-align: right;
  width: 79%;
  max-width: 1100px;
  font-size: 1.3rem;
  color: blue;
  font-style: oblique;
}

.offers__link:hover{
  color: darkblue;
  text-decoration: underline;
}

@media screen and (max-width: 1190px) {

  .offers{
    grid-template-columns: 1fr 1fr;
    width: 95%;
    max-width: 500px;
  }

}