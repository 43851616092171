.title{
  font-size: 2.3rem;
  color: var(--font-color);
  margin: 3rem auto 2rem auto;
  text-align: center;
  max-width: 90%;
}

.subtitle{
  font-size: 1.5rem;
  color: var(--secundary-color);
  margin: 1rem auto -2rem auto;
  text-align: center;
  max-width: 90%;
}

.icon{
  width: 30px;
  margin: 0 3px;
  transition: all .3s;
}

.icon:hover{
  opacity: .6;
  cursor: pointer;
}

.button::after {
  content: '';
  position: absolute;
  top: -50%;
  right: 0;
  bottom: -50%;
  left: -200px;
  background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255,255,255,0.5) 50%, rgba(229, 172, 142, 0));
  transform: rotateZ(60deg) translate(-5em, 7.5em);
}

.button {
  margin: -.5rem auto 1rem auto;
  background-image: url(../assets/img/button.png);
  background-size: 100%;
  font-size: 1.5rem;
  border-radius: 0.5em;
  width: 150px;
  height: 62.7px;
  position: relative;
  overflow: hidden;
  line-height: 32px;
  border: 0;
}

.button:hover::after, .button:focus::after {
  animation: sheen 1s forwards;
}

@keyframes sheen {
  100% {
    transform: rotateZ(60deg) translate(1em, -300px);
  }
}