.home__instagram {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  text-align: center;
  width: 100%;
}

.home__instagram--perfil{
  width: 80%;
  max-width: 350px;
  transition: all .3s;
}

.home__instagram--perfil:hover{
  opacity: .8;
}

.home__brands{
  width: 80%;
  margin-top: 1rem;
}

.home__brands--img{
  text-align: center;
}

.home__tektelecom{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home__tektelecom--subtitle{
  font-size: 1.5rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  font-weight: bold;
  max-width: 80%;
}

.home__tektelecom--text{
  max-width: 80%;
  font-size: 1rem;
  line-height: 1.5rem;
}