.center{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
}

.budget{
  display: flex;
  flex-direction: column;
}

.budget__mensage{
  width: 442px;
  height: 60px;
  border-radius: 10px;
  margin-bottom: .7rem;
  font-size: 1.5rem;
  padding-left: 52px;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-image: url(../../assets//img/icon/whatsapp-black.png);
}

.budget__btn{
  width: 500px;
  height: 60px;
  background-color: var(--primary-color);
  border: 0;
  border-radius: 7px;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--thirdly-color);
  transition: all .5s;
}

.budget__btn:hover{
  cursor: pointer;
  background-color:#bd151c;
}

.budget__btn:active {
  background-color:darkred;
}

.budget__input__error {
  display: block;
  color: var(--primary-color);
  margin-top: -.35rem;
  margin-bottom: 1rem;
}

.budget__input{
  width: 475px;
  height: 45px;
  border-radius: 7px;
  margin-bottom: .7rem;
  font-size: 1.5rem;
  padding-left: 1rem;
  border: 2px solid var(--font-color);
}

.budget__txt{
  width: 475px;
  height: 100px;
  border-radius: 7px;
  margin-bottom: .7rem;
  font-size: 1.5rem;
  padding-left: 1rem;
  border: 2px solid var(--font-color);
}

.budget__txt:focus{
  outline: none;
}


@media screen and (max-width: 595px) {

  .budget__mensage{
    width: 80%;
  }
  
  .budget__btn{
    width: 100%;
  }

  .budget__input{
    width: 93%;
  }
  
  .budget__txt{
    width: 93%;
  }
}