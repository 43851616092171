.products__section {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, .1);
  text-align: center;
  padding-bottom: 1rem;
}

.products__nav {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: left;
  line-height: 1.4rem;
  transition: all .5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.products__nav--close {
  max-height: 8rem;
}

.products__filter {
  margin: 0 1rem;
  padding: 0 3rem;
  max-width: 10rem;
  font-style: oblique;
}

.products__title {
  font-size: 1.2rem;
  color: var(--font-color);
  margin: 1rem auto;
}

.products__menu {
  margin: 1rem auto 0 auto;
  color: blue;
  font-style: oblique;
}

.products__menu:hover {
  color: darkblue;
  text-decoration: underline;
  cursor: pointer;
}

.products__menu--open {
  display: inline;
}

.products__menu--close {
  display: none;
}

.products__results{
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  font-style: oblique;
}

.products__results--link{
  color: blue;
}

.products__results--link:hover {
  color: darkblue;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 753px) {
  .products__filter {
    margin: 0 .5rem;
    padding: 0 .5rem;
  }
}