.address{
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-template-rows: repeat(5, 2rem);
  column-gap: 1rem;
  width: 720px;
  margin: 100px auto;
  font-size: 1.1rem;
  color: var(--font-color);
}

.address--item{
  align-self: center;
  margin: -1.7rem;
  transition: all .5s;
}

.address--item:hover{
  color: var(--primary-color);
}

.address--qr{
  grid-column: 1 / 2;
  grid-row: 1 / 9;
  width: 90%;
  margin: auto;
  margin-right: 2rem;
}

.address--map{
  grid-column: 1 / 3;
  grid-row: 10 / 11;
  width: 100%;
  height: 400px;
  margin-top: 2rem;
}

.address--title{
  font-size: 1.5rem;
  color: var(--primary-color);
}

@media screen and (max-width: 815px) {
  
  .address{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: -1rem auto 1rem auto;
      font-size: 1rem;
  }

  .address--item{
      margin: .5rem;
      width: 80%;
  }

  .address--qr{
    display: none;
  }

  .address--map{
      order: 1;
  }

  
}