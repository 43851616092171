.about{
  width: 80%;
  margin: 0 auto;
  text-align: justify;
  line-height: 1.5rem;
}

.about__paragraph{
  margin-bottom: 1rem;
}

.about__subtitle{
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.about__subtitle--home{
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 1rem;
  max-width: 400px;
  max-width: 80%;
  color: var(--font-color);
}

.about__img{
  float: right;
  width: 500px;
  margin: 1rem;
  margin-top: 0;
  border-radius: 5px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, .3);
}

@media screen and (max-width: 850px) {
  .about__img{
    float: none;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
}