.footer{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  color: var(--thirdly-color);
  background-color: var(--primary-color);
  box-shadow: 0px -5px 5px rgba(0,0,0,.2);
}

.footer__nav{
  text-align: center;
}

.footer__list__item{
  line-height: 1.4rem;
  transition: all .5s;
}

.footer__list__item:hover{
  color: var(--secundary-color);
}

.footer__social{
  text-align: center;
}

.footer__title{
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: .5rem;
}

.footer__copyright{
  text-align: center;
  background-color: var(--primary-color);
  display: block;
  color: var(--thirdly-color);
  padding: .5rem;
}

@media screen and (max-width: 500px) {

  .footer{
    flex-direction: column;
  }

  .footer__nav{
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

}