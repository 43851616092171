.carousel{
  margin-top: 1rem;
  width: 100%;
  background-image: url('../../../assets/img/slide-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, .3);
  transition-property: background-image;
  transition-duration: 2s;
}

.carousel--opacity{
  background-image: none;
}

.carousel--gamer{
  width: 100%;
  background-image: url('../../../assets/img/slide-background2.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel__slide{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.carousel__slide > img{
  width: 37%;
}


@media screen and (max-width: 600px) {

  .carousel__slide{
    flex-direction: column;
  }

  .carousel__slide > img{
    width: 65%;
  }
}