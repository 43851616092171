.card {
  padding: 1rem;
  background-color: #ffffff;
  margin: .5rem;
  font-weight: 200;
  border-radius: 15px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, .3);
  transition: all .3s;
}

.card:hover {
  opacity: .8;
}

.card__offer{
  margin-top: -35px;
  position: relative;
  display: block;
  text-align: center;
}

.card__img {
  width: 200px;
  height: 200px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: .3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__img--img {
  height: auto;
  max-height: 200px;
  width: auto;
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
}


.card__price{
  margin-top: .2rem;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  color:rgba(0, 0, 0, .75);
}

.card__span{
  font-size: 1rem;
}

.card__product{
  font-size: 1.3rem;
  max-width: 200px;
}

.card__brand{
  margin-top: 1rem;
  font-size: 1.1rem;
  color: var(--font-color);
}

.card__description{
  font-size: .85rem;
  color: var(--font-color);
  margin-top: .5rem;
}

@media screen and (max-width: 610px) {

  .card {
    padding: .5rem;
    max-width: 150px;
  }

  .card__offer{
    margin-top: -30px;
  }

  .card__offer > img{
    max-width: 135px;
  }

  .card__img {
    width: 150px;
    height: 150px;
    white-space: nowrap;
    overflow: hidden;
    border-radius: .3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card__img--img {
    height: auto;
    max-height: 150px;
    width: auto;
    max-width: 150px;
  } 

  .card__price{
    font-size: 1.2rem;
    color:rgba(0, 0, 0, .75);
  }
  
  .card__span{
    font-size: .7rem;
  }
  
  .card__product{
    font-size: 1rem;
    max-width: 80%;
  }
  
  .card__brand{
    padding-top: 1rem;
    font-size: 1rem;
    color: var(--font-color);
  }

} 