body {
  font-family: 'Montserrat', sans-serif;
  background-color: var(--thirdly-color);
}

main{
  padding-top: 6.5rem;
}

@media screen and (max-width: 600px) {
  main{
    padding-top: 8rem;
  }
}