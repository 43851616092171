.header{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--thirdly-color);
  box-shadow: 0px 5px 5px rgba(0,0,0,.2);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: 1.25rem;
}

.header__logo{
  margin-left: 5%;
}

.header__logo__img{
  width: 100px;
}

.header__list{
  display: flex;
}

.header__list__link{
  font-size: 1rem;
  color: var(--secunday-color);
  margin-left: 2rem;
  transition: all .5s;
}

.header__list__link:hover{
  color: var(--primary-color);
}

.header__menu{
  display: flex;
  align-items: center;
  margin-right: 5%;
}

.header__search{
  padding: .5rem;
  border-radius: 10px;
  width: 150px;
  padding-left: 45px;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-image: url(../../assets//img/icon/search.png);
  background-size: 15px;
  border-color: rgba(0, 0, 0, .3);
}

.header__search:focus{
  box-shadow: 0 4px 4px rgba(0, 0, 0, .5);
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: -20px;
  text-align: center;
  background-color: var(--thirdly-color);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 0px 0px 5px 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: -20px;
  text-align: center;
  background-color: var(--thirdly-color);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 0px 0px 5px 5px;
}

.dropdown-content a {
  color: black;
  padding: 12px .5rem;
  text-decoration: none;
  display: block;
  border-radius: 0px 0px 5px 5px;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown-content--show {
  display: block;
}

@-webkit-keyframes fadeIn {
  from {opacity: 0;} 
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}

@media screen and (max-width: 1135px) {

  .header__btn{
      display: block;
      background-image: url("../../assets/img/icon/hamburger.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 45px;
      height: 31px;
      margin-left: 20px;
      cursor: pointer;
  }

  .header__nav{
      background-color: var(--thirdly-color);
      transition: all .8s;
      position: absolute;
      width: 40%;
      min-width: 150px;
      left: calc(-100% - 10rem);
      top: 6rem;
      z-index: 2;
      border-radius: 0 0 10px 0;
  }

  .header__nav--open{
      left: 0%;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, .3);
  }

  .header__list{
      flex-direction: column;
      text-align: center;
  }

  .header__list__item{
      border-right: 0;
      padding: 0;
      margin-right: 0;
  }

  .header__list__link{
      margin: 0;
      padding: 1rem 0 1rem 0;
      border-bottom: .13rem solid rgba(0, 0, 0, 0.2);
      width: 100%;
  }

  .header__list__link:hover{
    color: var(--secunday-color);
    background-color: #ddd;
  }

  .header__list__link--last{
    border: 0;
  }

  .dropdown-content {
    top: 45px;
    left: 0;
    width: 100%;
  }

  .dropdown-content a {
    border-top: .13rem solid rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 600px) {

  .header{
    padding-bottom: 3.3rem;
  }

  .header__menu{
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column-reverse;
  }

  .header__btn{
    margin: 1rem;
  }

  .header__search{
    position: absolute;
    top: 90px;
    left: calc(25% - 35px);
    width: 50%;
  }

  .header__nav{
    top: 8.5rem;
  }
}