.admin{
  margin: 0 auto;
  text-align: center;
  width: 80%;
  max-width: 450px;
  margin-bottom: 1rem;
}

.admin__group__img{
  background-color: #ffffff;
  width: 200px;
  margin: 0 auto;
}

.admin__logout{
  font-size: 1.2rem;
  
}

.admin__logout:hover{
  cursor: pointer;
  color: var(--primary-color);
}
.admin__group__field {
  border: none;
  border-radius: 7px;
  background-color: #ffffff;
  color: var(--secundary-color);
  border: 2px solid var(--font-color);
  margin: 0 auto;
  margin-top: 1rem;
  padding: .75rem;
  font-size: 1.2rem;
  width: 100%;
  transition: all .15s linear;
}

.admin__group__field::placeholder {
  color: var(--secundary-color);
}

.admin__group__field--price {
  padding-left: 3rem;
  position: relative;
  width: calc(100% - 2.3rem);
  background-image: url(../../assets/img/icon/rs.png);
  background-repeat: no-repeat;
  background-size: 3rem;
}

.admin__group__field--select {
  width: 106%;
}

.admin__group__field:focus {
  box-shadow: 0 4px 4px rgba(0, 0, 0, .5);
  outline: none;
}

.admin__group__field--textarea {
  resize: none;
  display: block;
  height: 5rem;
}

.admin__group__error {
  color: var(--primary-color);
  line-height: 1.7rem;
}

.admin__btn {
  background: var(--primary-color);
  color: var(--thirdly-color);
  font-weight: bold;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  margin-top: 1.5rem;
  transition: all .3s;
}

.admin__btn:hover {
  cursor: pointer;
  background-color: rgb(128, 3, 3);
}

.admin__checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 1rem auto;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.admin__check {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.admin__checkmark {
  position: absolute;
  top: 0;
  left: 40%;
  height: 25px;
  width: 25px;
  background: var(--secundary-color);
  border-radius: 5px;
  transition: all .3s;
}

.admin__checkbox:hover .admin__check ~ .admin__checkmark {
  background-color: rgb(206, 57, 57);
}

.admin__checkbox .admin__check:checked ~ .admin__checkmark {
  background-color: var(--primary-color);
}

.admin__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.admin__checkbox .admin__check:checked ~ .admin__checkmark:after {
  display: block;
}

.admin__checkbox .admin__checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}